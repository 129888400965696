<template>
  <base-section
    id="video"
  >
     <base-section-heading
        color="transparent"
        title="ATENÇÃO!"
        class="px-6"
      >
        <h3>Veja o próximo vídeo se você quer ter os melhores resultados!</h3>
      </base-section-heading>
    <v-container>
      <v-row>
        <v-col>
          <v-lazy
            v-model="isActive"
            :options="{
              threshold: .5
            }"
            min-height="200"
            transition="fade-transition"
          >
            <vue-plyr class="elevation-12">
              <div
                data-plyr-provider="youtube"
                data-plyr-embed-id="2Qc5ioWwckE"
              />
            </vue-plyr>
          </v-lazy>
        </v-col>
      </v-row>
      <base-btn-plans />
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'Video',

    data () {
      return {
        isActive: false,
      }
    },
  }
</script>
